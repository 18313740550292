<template>
  <div
    v-if="
      curatorSettings &&
      mainStore.currentSite &&
      mainStore.currentSite.settings.curator
    "
    class="bg-[#0f0f0f] md:px-16"
  >
    <div class="mx-auto max-w-[2560px] py-6 px-4">
      <div :id="curatorSettings.id" />
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

import { useMainStore } from "@/store/index";
const curatorFeeds = {
  // International
  default: {
    id: "curator-feed-default-feed-layout",
    src: "https://cdn.curator.io/published/5fb8b017-9e7c-4fa0-9f21-23e8ceeb6cdb.js",
  },
  // Romania
  ro: {
    id: "curator-feed-ezi-romania-layout",
    src: "https://cdn.curator.io/published/c7220fb7-1d04-4d46-9db8-a386bedde558.js",
  },
};
const mainStore = useMainStore();

const curatorSettings = reactive(
  curatorFeeds[mainStore.country]
    ? curatorFeeds[mainStore.country]
    : curatorFeeds.default
);

const scripts = [];
if (process.browser && mainStore.currentSite.settings.curator) {
  scripts.push({
    // class: '_iub_cs_activate',
    // 'data-suppressedsrc': curatorFeeds[mainStore.country] ? curatorFeeds[mainStore.country].src : curatorFeeds.default.src
    // 'data-iub-purposes': 3,
    crossorigin: true,
    defer: true,
    // type: 'text/plain'
    src: curatorFeeds[mainStore.country]
      ? curatorFeeds[mainStore.country].src
      : curatorFeeds.default.src,
  });
}

useHead({
  script: scripts,
});
</script>

<style lang="postcss">
@media screen and (max-width: 768px) {
  .crt-widget-carousel {
    .crt-controls-outside {
      @apply text-center px-0 !important;

      button {
        @apply inline relative bottom-[-60px] !important;

        &.crt-panel-prev {
          @apply me-4 !important;
        }
      }
    }

    .crt-match-heights .crt-carousel-pane {
      border: 1px solid transparent !important;
    }
  }
}

.crt-feed-window {
  @apply !h-auto;
}

.crt-widget-waterfall {
  min-height: auto !important;
  padding-bottom: 0 !important;
}

.crt-panel-next svg,
.crt-panel-prev svg {
  width: 100% !important;
}

.crt-widget {
  @apply !bg-[#0f0f0f];
}
</style>
